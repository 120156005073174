import { Link, navigate } from "gatsby";
import btnEmailSubmit from "src/images/btn-chevron.png";
import "../styles/polb.scss";

const React = require('react');
const AriaModal = require('react-aria-modal');
// See https://github.com/davidtheclark/react-aria-modal for more info and demos

/**
 *  Show video in modal, such as from Watch Video play button Home
 */
class EmailSignup extends React.Component {
  constructor(props) {
    super(props);

    // this.activateModal = this.activateModal.bind(this);
    this.deactivateModal = this.deactivateModal.bind(this);
    this.getApplicationNode = this.getApplicationNode.bind(this);
  }

  state = {
    modalActive: true,
  };

  // activateModal = () => {
  //   this.props.updateState();
  //   this.setState({ modalActive: true });
  // };

  deactivateModal = () => {
    this.setState({ modalActive: false });
    navigate("/");
  };

  getApplicationNode = () => {
    return document.getElementById('application');
  };

  onTodoChange(value) {
    this.setState({
      email: value
    });
  }

  render() {
    const modal = this.state.modalActive
      && <AriaModal
        titleText="Newsletter Signup"
        onExit={this.deactivateModal}
        getApplicationNode={this.getApplicationNode}
        verticallyCenter={true}
        underlayColor="#030447"
        underlayClickExits={false}
      >
        <div className="modal modal--signupForm gutter">
          <div className="modal__body" id="mc_embed_signup">
            <form action="https://polb.us6.list-manage.com/subscribe/post?u=179c9a35d1a49483d46ede9e8&amp;id=ff1d139cd0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
              <div id="mc_embed_signup_scroll">
                <h2>Subscribe</h2>
                <div className="indicates-required"><span class="asterisk">*</span> indicates required</div>
                <br />
                <div className="mc-field-group">
                  <label htmlFor="mce-EMAIL" className="strongLabel">Email Address <span className="asterisk">*</span>
                  </label>
                  <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" required />
                </div>
                <div className="mc-field-group">
                  <label htmlFor="mce-FNAME" className="strongLabel">First Name </label>
                  <input type="text" name="FNAME" className="" id="mce-FNAME" />
                </div>
                <div className="mc-field-group">
                  <label htmlFor="mce-LNAME" className="strongLabel">Last Name </label>
                  <input type="text" name="LNAME" className="" id="mce-LNAME" />
                </div>
                <div className="mc-field-group input-group">
                  <strong>News Updates </strong>
                  <ul>
                    <li>
                      <li><input type="checkbox" value="1" name="group[5161][1]" id="mce-group[5161]-5161-0" />
                        <label htmlfor="mce-group[5161]-5161-0">News and Information</label></li>
                    </li>
                    <li><input type="checkbox" value="2" name="group[5161][2]" id="mce-group[5161]-5161-1" />
                      <label for="mce-group[5161]-5161-1">Cargo Statistics</label></li>
                    <li><input type="checkbox" value="4096" name="group[5161][4096]" id="mce-group[5161]-5161-2" />
                      <label htmlfor="mce-group[5161]-5161-2">Harbor Commission Agendas and Minutes</label></li>
                    <li><input type="checkbox" value="4" name="group[5161][4]" id="mce-group[5161]-5161-3" />
                      <label htmlfor="mce-group[5161]-5161-3">Community Grants Program</label></li>
                    <li><input type="checkbox" value="576460752303423488" name="group[5161][576460752303423488]" id="mce-group[5161]-5161-4" />
                      <label htmlfor="mce-group[5161]-5161-4">Supply Chain Information Highway</label></li>
                    <li><input type="checkbox" value="1048576" name="group[5161][1048576]" id="mce-group[5161]-5161-7" />
                      <label htmlfor="mce-group[5161]-5161-7">Clean Air Action Plan</label></li>
                    <li><input type="checkbox" value="16777216" name="group[5161][16777216]" id="mce-group[5161]-5161-8" />
                      <label htmlfor="mce-group[5161]-5161-8">Port Master Plan Update</label></li>
                    <li><input type="checkbox" value="144115188075855872" name="group[5161][144115188075855872]" id="mce-group[5161]-5161-10" />
                      <label htmlfor="mce-group[5161]-5161-10">Pier B Updates</label></li>
                    <li><input type="checkbox" value="134217728" name="group[5161][134217728]" id="mce-group[5161]-5161-11" />
                      <label htmlfor="mce-group[5161]-5161-11">CEQA Environmental Notifications</label></li>
                  </ul>
                </div>
                <div className="mc-field-group input-group">
                  <strong>Jobs/Careers</strong>
                  <ul>
                    <li><input type="checkbox" value="8589934592" name="group[8126][8589934592]" id="mce-group[8126]-8126-1" />
                      <label htmlfor="mce-group[8126]-8126-1">Jobs/Careers</label></li>
                  </ul>
                </div>
                <div className="mc-field-group input-group">
                  <strong>Publications </strong>
                  <ul>
                    <li><input type="checkbox" value="32" name="group[5165][32]" id="mce-group[5165]-5165-1" />
                      <label htmlfor="mce-group[5165]-5165-1">Tie Lines</label></li>
                  </ul>
                </div>
                <div className="mc-field-group input-group">
                  <strong>Community Updates </strong>
                  <ul>
                    <li><input type="checkbox" value="524288" name="group[5177][524288]" id="mce-group[5177]-5177-0" />
                      <label htmlfor="mce-group[5177]-5177-0">Community Outreach/YourPort Newsletter</label></li>
                    <li><input type="checkbox" value="4194304" name="group[5177][4194304]" id="mce-group[5177]-5177-1" />
                      <label htmlfor="mce-group[5177]-5177-1">Community Sponsorships</label></li>
                  </ul>
                </div>
                <div className="mc-field-group input-group">
                  <strong>Email Format </strong>
                  <ul>
                    <li><input type="radio" value="html" name="EMAILTYPE" id="mce-EMAILTYPE-0" />
                      <label htmlfor="mce-EMAILTYPE-0">html</label></li>
                    <li><input type="radio" value="text" name="EMAILTYPE" id="mce-EMAILTYPE-1" />
                      <label for="mce-EMAILTYPE-1">text</label></li>
                  </ul>
                </div>
                <div id="mce-responses" className="clear">
                  <div className="response" id="mce-error-response" hidden></div>
                  <div className="response" id="mce-success-response" hidden></div>
                </div>
                <div aria-hidden="true" hidden>
                  <input type="text" name="b_179c9a35d1a49483d46ede9e8_ff1d139cd0" tabIndex="-1" />
                </div>
                <div className="clear">
                  {/* <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="main--link" /> */}
                  <button type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="main--link">
                    <span className="main--link__text">Subscribe</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
          <footer className="modal__footer">
            <button type="button" id="demo-one-deactivate" onClick={this.deactivateModal}>
              close modal
              </button>
          </footer>
          <Link to="/">Return to the Port of Long Beach site</Link>
        </div>

      </AriaModal>

    return (
      <div>
        {/* <button type="submit" className="form-email__btn button" onClick={this.activateModal} name="subscribe"><img src={btnEmailSubmit} alt="submit email address" /></button> */}
        {modal}
      </div>
    );
  }
}

export default EmailSignup;
